#htmlcontent_home {
  margin: 0; }
  #htmlcontent_home ul {
    padding: 0;
    list-style: none; }
    #htmlcontent_home ul li {
      padding: 0 15px;
      width: 100%; }
      #htmlcontent_home ul li img {
        width: 100%;
        height: auto; }

#htmlcontent_top {
  width: 100%; }
  #htmlcontent_top ul {
    padding: 0;
    margin: 0;
    list-style: none; }
    #htmlcontent_top ul li {
      float: left;
      width: 100%;
      padding: 0 15px;
      margin-bottom: 15px; }
      #htmlcontent_top ul li img {
        max-width: 100%;
        height: auto; }
